const trans={
    de:{
        title:"Überschrift",
        changeTheme:"Design wählen",
        main:"Hauptteil",
        footer:"Fußnote",
        page1:"Forschritt der Website",
        page2:"Lebenslauf",
        proj:"Projekte",
        logIn:"Log In",
        signIn:"Profil erstellen",
        gender:"Anrede",
        male:"Herr",
        female:"Frau",
        firstname:"Vorname",
        lastname:"Nachname",
        username:"Benutzername",
        password:"Passwort",
        dateob:"Geburtsdatum",
        submit:"Nutzer speichern",
        loginAlert:"Bitte erste alle Daten ausfüllen!",
        wrongLogIn:"falsche Einlog-Daten",
        signUpAlert:"Bitte erst alle Felder ausfüllen!",
        steps:"Status",
        status:"Meilenstein",
        date:"Datum",
        used:"Tools",
        cv:"Lebenslauf erstellt",
        backE:"Grundliegende Backendfuntionen",
        mongo:"Mongoo DB erstellt und online",
        frontend:"Basis Frontend",
        tranlations:"Übersetzung English/Deutsch",
        styling:"Erstes Styling",
        deploy:"Frontend geht online",
        routes:"Projekte zeigen",
        domain:"Domain zuweisen",
        routing:"Routing verbessern",
        backend:"Backend online bringen",
        response:"Responsivness verbessern",
        api:"Wetter oder Maps integrieren",
        landing:"Landing Page erstellen",
        underConst:"Die Website befindet sich noch in der Entwicklung. Den Fortschritt erfährst Du im Reiter Fortschritt der Website.",
        projects:"Meine Projekte",
        kniffel:"Kniffel",
        kniffelText:"Erste JS-App vor DCI",
        ffText:"Erste Website vor DCI",
        example:"Sehr einfaches Beispiel",
        exampleText:"Zum Tutoring genutzt",
        repFront:"Wiederholung Frontend",
        repFrontText:"Wiederhohlung aller Frontend Tools",
        mernText:"Erstes MERN Fullstack Projekt",
        this:"Diese Seite",
        tutoringStudens:"Tutor für Studenten in Web Entwicklung",
        finalProject:"friendscircle.de",
        finalProjectText:"Abschluss Projekt",
        intern:"Praktikum Software-Entwickler/Manager",
        learningC:"C# lernen",
        later:"Später: Vue.js, Blazor, Flutter, Beratung und Teamführung",
        comparism:"FullStack-Projekt in C# und Express",
        dmk:"Business Software-Developer/Product Owner",
        seit:"seit",
        kundenkontakt:"Kundenkberatung-/Betreuung bzgl. Softwareprojekten",
        management:"Planung und Management der Softwareprojekte",
        entwicklung:"Softwareentwicklung",
        praktikanten:"Betreuung der Praktikanten"
    },
    en:{
        title:"title",
        changeTheme:"change theme",
        main:"mainpart",
        footer:"footer",
        page1:"Construction progress",
        page2:"CV",
        proj:"Projects",
        logIn:"Log In",
        signIn:"Sign In",
        gender:"gender",
        male:"male",
        female:"female",
        firstname:"first Name",
        lastname:"last Name",
        username:"username",
        password:"password",
        dateob:"date of birth",
        submit:"save user",
        loginAlert:"Please fill out all required data!",
        wrongLogIn:"wrong Login Date",
        signUpAlert:"Please fill out the whole form!",
        steps:"Status",
        status:"Milestone",
        date:"Date",
        used:"techniques",
        cv:"CV created",
        backE:"basics Backend coded",
        mongo:"Mangoo Database online",
        frontend:"general Frontend structure",
        tranlations:"Translation German/English",
        styling:"general Styling",
        deploy:"Frontend went online",
        routes:"Links to projects",
        domain:"connect to domain",
        routing:"improve Routing",
        backend:"bringing Backend online",
        response:"improve responsivness",
        api:"integrating maps or weather",
        landing:"creating a landing page",
        underConst:"The website is still under construction. The progress you can see under Construction progress.",
        projects:"My Projects",
        kniffel:"Jazzy",
        kniffelText:"First JS-App even before DCI",
        ffText:"First Website befor DCI",
        example:"Simple Example",
        exampleText:"Used for Tutoring",
        repFront:"Repeating Frontend",
        repFrontText:"Repeating all Frontend Tools",
        mernText:"First MERN Fullstack Project",
        this:"These Page",
        tutoringStudens:"Tutoring students from a different class",
        finalProject:"friendscircle.de",
        finalProjectText:"Final project",
        intern:"Internship Software-Developer/Manager",
        learningC:"learning C#",
        later:"planned for later: Vue.js, Blazor, Flutter, advisory und guiding the team",
        comparism:"FullStack-project in C# and Express",
        dmk:"Business Software-Developer/Product Owner",
        seit:"since",
        kundenkontakt:"Customer advice/care regarding software projects",
        management:"Planning and management of software projects",
        entwicklung:"Softwaredevelopment",
        praktikanten:"Supervision of interns"
    },
    ke:{
        title:"title",
        changeTheme:"change theme",
        main:"mainpart",
        footer:"footer",
        page1:"Contruction progress",
        page2:"CV",
        proj:"Projects",
        logIn:"Log In",
        signIn:"Sign In",
        gender:"gender",
        male:"male",
        female:"female",
        firstname:"first Name",
        lastname:"last Name",
        username:"username",
        password:"password",
        dateob:"date of birth",
        submit:"save user",
        loginAlert:"Please fill out all required data!",
        wrongLogIn:"wrong Login Date",
        signUpAlert:"Please fill out the whole form!",
        steps:"Status",
        status:"Milestone",
        date:"Date",
        used:"techniques",
        cv:"CV created",
        backE:"basics Backend coded",
        mongo:"Mangoo Database online",
        frontend:"general Frontend structure",
        tranlations:"Translation German/English",
        styling:"general Styling",
        deploy:"Frontend went online",
        routes:"Links to projects",
        domain:"connect to domain",
        routing:"improve Routing",
        backend:"bringing Backend online",
        response:"improve responsivness",
        api:"integrating maps or weather",
        landing:"creating a landing page",
        underConst:"The website is still under construction. The progress you can see under Construction progress.",
        projects:"My Projects",
        kniffel:"Jazzy",
        kniffelText:"First JS-App even before DCI",
        ffText:"First Website befor DCI",
        example:"Simple Example",
        exampleText:"Used for Tutoring",
        repFront:"Repeating Frontend",
        repFrontText:"Repeating all Frontend Tools",
        mernText:"First MERN Fullstack Project",
        this:"These Page",
        tutoringStudens:"Tutoring students from a different class",
        finalProject:"friendscircle.de",
        finalProjectText:"Final project",
        intern:"Internship Software-Developer/Manager",
        learningC:"learning C#",
        later:"planned for later: Vue.js, Blazor, Flutter, advisory und guiding the team",
        comparism:"FullStack-project in C# and Express",
        dmk:"Business Software-Developer/Product Owner",
        seit:"since",
        kundenkontakt:"Customer advice/care regarding software projects",
        management:"Planning and management of software projects",
        entwicklung:"Softwaredevelopment",
        praktikanten:"Supervision of interns"
    }
}
export default trans